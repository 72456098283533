@import "app/styles/variables";

.action {
  margin-left: $spacing-small;
  margin-right: $spacing-small;

  &:before {
    background-color: transparent;
  }
}
