@import "app/styles/colors";
@import "app/styles/variables";

.container {
  position: relative;
  width: 100%;
  height: 50vh;
}

/* 
    shared for mark and cluster  and Site
*/
.mark,
.cluster,
.site {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    // be sure to cover all other clusters and marks
    z-index: 10000;
  }
}

/* cluster  */

.cluster {
  font-size: $font-size-xlarge;
  line-height: 1.2;
  height: 2em;
  min-width: 2em;
  border-radius: 1em;
  padding: $spacing-small 12px;
  background: $neutral-dark-blue;
  color: white;
  text-align: center;
}

/*
    shared for hidden info on mark and cluster 
*/
.markInfo,
.clusterInfo {
  font-size: $font-size-xsmall;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s 0.1s, transform 0.2s 0.1s, visibility 0s 0s;
  transition-timing-function: linear;
  z-index: 1;

  span {
    display: block;
    white-space: nowrap;
    line-height: 1.3;
  }
}

.clusterInfo {
  width: auto;
  height: auto;
  padding: $spacing-xsmall;
  background: rgba($black, 0.8);
  border-radius: $border-radius-large;
  cursor: default;
  pointer-events: none;
}

.markInfo {
  padding: $spacing-small;
  border: 1px solid $neutral-grey;
  background: $white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); // taken from ant-modal
  span {
    line-height: 2;
  }
}

.cluster:hover {
  .clusterInfo {
    visibility: visible;
    opacity: 1;
  }
}
.mark:hover {
  .markInfo {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -105%);
  }
}

/* keep code while we figure out detail mode

.details {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  background: rgba(red, 0.2);
  transform: translate(-50%, -50%);
}
*/
// Site overwrites
.site {
  transform: translate(-50%, -50%) scale(0.7);
  cursor: default;
  z-index: 0;
}
