@import "app/styles/colors";
@import "app/styles/variables";

.container {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 320px;
  transition-property: width, height, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  z-index: 1;

  &.full {
    background-color: $white;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: $spacing-large;
  }
}

.closeMap {
  font-size: $font-size-xxlarge;
  position: fixed;
  top: 0;
  right: 0;
  background-color: $white;
  z-index: 2;
  padding: $spacing-medium;
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
  transform: translateX(100%);

  &.active {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.3s;
  }
}
