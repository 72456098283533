@import "app/styles/fonts/font.css";
@import "app/styles/colors";

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: fadeAnimation 800ms linear;
}

main {
  animation: fadeAnimation 800ms linear;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

.ant-table-column-title,
.ant-modal-title,
.ant-card-head-title {
  text-transform: uppercase;
}

.ant-badge-count {
  background-color: $blue-darkest !important;
}

.ant-table-row-custom-cursor {
  cursor: pointer;
}

.ant-card .ant-card-body,
.ant-drawer .ant-drawer-body {
  animation: fadeAnimation 2000ms ease-in-out;
}
