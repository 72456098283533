@import "app/styles/variables";

$select-max-height: 40px;

.select {
  width: 100%;
  margin-bottom: $spacing-medium;
  :global(.ant-select-selection--multiple) {
    max-height: $select-max-height;
    overflow-y: scroll;
  }
}
