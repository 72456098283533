@import "app/styles/colors";
@import "app/styles/variables";

.table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;

  tbody {
    td,
    th {
      border-bottom: 1px solid $gray-4;
      text-align: left;
      padding: 10px;
      font-weight: normal;
    }
  }
}

.tableBordered {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;

  tbody {
    border: 2px solid $gray-1;

    td,
    th {
      border-bottom: 1px solid $gray-4;
      text-align: left;
      padding: 10px 34px;
      font-weight: normal;
      span {
        &:last-child {
          margin-left: 25px;
        }
      }
    }
  }

  tfoot {
    td {
      text-align: left;
      padding: 10px;
      font-weight: normal;
    }
  }
}

.testKit {
  margin: 40px auto;
}

.timeLineContainer {
  padding: 20px 16px;
  border: 1px solid $gray-1;
}

.verificationTitle {
  margin-bottom: $spacing-none;
  padding: $spacing-small $spacing-medium;
  border-top: 2px solid $gray-1;
  border-right: 2px solid $gray-1;
  border-left: 2px solid $gray-1;
}

.lensPerformanceLastColum {
  border-right: 1px solid $gray-4;
}

.infoAdditionalCommentHeader {
  margin-top: 10px;
  margin-left: 10px;
}

.infoAdditionalComments {
  margin-left: 10px;
}

.notAvailable {
  text-align: center;
  margin-top: 50px;
}

.textRight {
  text-align: right !important;
}
