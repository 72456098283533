@import "app/styles/variables";

.switchLabel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  :global {
    .ant-form-item-label {
      margin-left: $spacing-medium;
      padding: 0;

      &,
      label {
        cursor: pointer;
      }
    }
  }
}
