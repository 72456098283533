@import "app/styles/variables";

.select {
  width: 100%;
  margin-bottom: $spacing-medium;
}

.extra {
  display: flex;
  justify-content: flex-end;
}

.extraContent {
  display: inline-flex;
}
