@import "app/styles/colors";

.table {
  :global(.ant-table-scroll) :global(.ant-table-body) {
    overflow-x: auto !important; // !important needed to overwrite inline styles from Ant Design
  }

  :global(.ant-table-small) {
    border: none;
    margin: 0;

    & > :global(.ant-table-content > .ant-table-body) {
      margin: 0;
    }
  }

  :global(.ant-table-row-expand-icon) {
    line-height: 17px;
  }

  :global(.ant-table-expanded-row) {
    background-color: $neutral-light-grey;

    &:hover {
      background-color: $neutral-grey;
    }
  }
}
