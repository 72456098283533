.editor {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    :global {
        .ql-container {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
        }
        .ql-editor {
            flex-grow: 1;
        }
    }
}
