@import "app/styles/variables";

.modal {
  max-width: 1200px;
  width: 90% !important; // Necessary in order to override Ant Design inline styles
  @media screen and (max-width: $screen-xs) {
    width: 95% !important;
  }
  :global(.ant-modal-body) {
    padding: $spacing-xxlarge;
    @media screen and (max-width: $screen-xs) {
      padding: $spacing-large;
    }
  }
}
