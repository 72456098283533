@import "app/styles/colors";
@import "app/styles/variables";

.column {
  float: 0 0 auto;
  width: 100%;

  @media screen and (min-width: $screen-md) {
    width: 50%;
  }

  @media screen and (min-width: $screen-xl) {
    width: 25%;
  }

  &:first-child:nth-last-child(odd),
  &:first-child:nth-last-child(odd) ~ & {
    @media screen and (min-width: $screen-md) {
      width: 100%;
    }

    @media screen and (min-width: $screen-lg) {
      width: 33.33333333%;
    }
  }
}

.link {
  border: 2px solid $brand-primary;
  color: $brand-primary;
  font-size: $font-size-xxlarge;
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $brand-primary;
    color: $white;
  }
}

.icon {
  display: block;
  margin-bottom: $spacing-small;
}
