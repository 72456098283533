@import "app/styles/variables";
@import "app/styles/colors";

.actionList {
  border: 1px solid $neutral-grey;
  padding: $spacing-none;
  :global(.ant-dropdown-menu-item) {
    padding: $spacing-none;
  }
  .action {
    border-bottom: 1px solid $neutral-grey;
    padding: $spacing-small $spacing-xlarge $spacing-small $spacing-medium;
    cursor: pointer;
    &:hover {
      background-color: $brand-primary;
      color: $white;
    }
  }
}
:global(.ant-popover) {
  z-index: 1051;
}
