@import "app/styles/colors";

.spinner {
  background-color: rgba($white, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
