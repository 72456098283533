@font-face {
  font-family: "NeuzeitGrotesk";
  src: url("NeuzeitGro-Lig.eot");
  src: url("NeuzeitGro-Lig.eot?#iefix") format("embedded-opentype"),
    url("NeuzeitGro-Lig.svg#NeuzeitGro-Lig") format("svg"),
    url("NeuzeitGro-Lig.ttf") format("truetype"),
    url("NeuzeitGro-Lig.woff") format("woff"),
    url("NeuzeitGro-Lig.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NeuzeitGrotesk";
  src: url("NeuzeitGro-Reg.eot");
  src: url("NeuzeitGro-Reg.eot?#iefix") format("embedded-opentype"),
    url("NeuzeitGro-Reg.svg#NeuzeitGro-Reg") format("svg"),
    url("NeuzeitGro-Reg.ttf") format("truetype"),
    url("NeuzeitGro-Reg.woff") format("woff"),
    url("NeuzeitGro-Reg.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeuzeitGrotesk";
  src: url("NeuzeitGro-Bol.eot");
  src: url("NeuzeitGro-Bol.eot?#iefix") format("embedded-opentype"),
    url("NeuzeitGro-Bol.svg#NeuzeitGro-Bol") format("svg"),
    url("NeuzeitGro-Bol.ttf") format("truetype"),
    url("NeuzeitGro-Bol.woff") format("woff"),
    url("NeuzeitGro-Bol.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NeuzeitGrotesk";
  src: url("NeuzeitGro-Bol.eot");
  src: url("NeuzeitGro-Bol.eot?#iefix") format("embedded-opentype"),
    url("NeuzeitGro-Bol.svg#NeuzeitGro-Bol") format("svg"),
    url("NeuzeitGro-Bol.ttf") format("truetype"),
    url("NeuzeitGro-Bol.woff") format("woff"),
    url("NeuzeitGro-Bol.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NeuzeitGrotesk";
  src: url("NeuzeitGro-Bol.eot");
  src: url("NeuzeitGro-Bol.eot?#iefix") format("embedded-opentype"),
    url("NeuzeitGro-Bol.svg#NeuzeitGro-Bol") format("svg"),
    url("NeuzeitGro-Bol.ttf") format("truetype"),
    url("NeuzeitGro-Bol.woff") format("woff"),
    url("NeuzeitGro-Bol.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NeuzeitGrotesk";
  src: url("NeuzeitGro-Bla.eot");
  src: url("NeuzeitGro-Bla.eot?#iefix") format("embedded-opentype"),
    url("NeuzeitGro-Bla.svg#NeuzeitGro-Bla") format("svg"),
    url("NeuzeitGro-Bla.ttf") format("truetype"),
    url("NeuzeitGro-Bla.woff") format("woff"),
    url("NeuzeitGro-Bla.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
