@import "app/styles/colors";

%kitAfterward {
  content: "";
  background-color: $white;
  width: 20px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  bottom: 45px;
}

.kitContainer {
  width: 40px;
  min-height: 215px;
  padding: 6px;
  background-color: $gray-2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  &:before {
    @extend %kitAfterward;
    left: -15px;
  }
  &:after {
    @extend %kitAfterward;
    right: -15px;
  }
}

.kitTopHolder {
  padding: 15px 0;
  border: 1px solid $gray-3;
  border-radius: 4px;
  flex-grow: 1;
}

.kitStripeHolder {
  position: relative;
  padding: 10px 0;
}

.kitStripe {
  height: 6px;
}

.kitStripeNumber {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  transform: rotate(-90deg);
  position: absolute;
  top: 5px;
  left: 5px;
}

.failedStripe {
  background-color: $red-1;
}

.failedNumber {
  background-color: $red-lightest;
  color: $red-1;
}

.passedStripe {
  background-color: $brand-secondary;
}

.passedNumber {
  background-color: $green-lightest;
  color: $brand-secondary;
}

.kitBottomHolder {
  height: 65px;
}
