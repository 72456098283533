@import "app/styles/colors";
@import "app/styles/variables";

.modal {
  max-width: $screen-xl;

  width: 80% !important; // Necessary in order to override Ant Design inline styles
}

.row {
  &:not(:first-child) {
    border-top: 1px solid $neutral-grey;
    margin-top: $spacing-medium;
    padding-top: $spacing-medium;
  }

  &.mapRow {
    padding-top: $spacing-large;
  }

  &.loadingRow {
    text-align: center;
  }
}

.mainRow {
  margin-top: $spacing-large;
}

.testDetails {
  margin-top: $spacing-large;

  @media screen and (min-width: $screen-lg) {
    margin-top: 0;
  }
}

.card {
  background-color: $neutral-grey;
}

.image {
  height: auto;
  width: 100%;
}

.text {
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.mapContainer {
  height: 300px;

  @media screen and (min-width: $screen-lg) {
    height: 100%;
    min-height: 300px;
  }
}

.mobileMap {
  display: block;

  @media screen and (min-width: $screen-lg) {
    display: none;
  }
}

.desktopMap {
  display: none;

  @media screen and (min-width: $screen-lg) {
    display: block;
  }
}
