@import "app/styles/variables";
@import "app/styles/colors";

.content {
  height: 100%;
  text-align: center;

  :global(.ant-layout-content) & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > * {
    margin-bottom: $spacing-medium;
  }
  .icon {
    margin-bottom: $spacing-xlarge;
    font-size: $font-size-4xlarge;
    color: $brand-primary;
  }

  .header {
    font-size: $font-size-xlarge;
    @media screen and (min-width: $screen-md) {
      font-size: $font-size-xxlarge;
      width: 50%;
    }
  }
  .descriptionText {
    margin-bottom: $spacing-xxlarge;
    @media screen and (min-width: $screen-md) {
      width: 60%;
    }
  }
}
