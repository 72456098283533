@import "app/styles/variables";
@import "app/styles/colors";

.container {
  position: relative;
}

.background {
  background-color: $neutral-light-grey;
  padding: $spacing-xlarge;
}

.header {
  margin-bottom: $spacing-xlarge;
  min-height: $spacing-xxlarge;
  position: relative;
}

.title {
  color: $neutral-dark-blue;
  line-height: 1;
  margin-bottom: 0;
}

.subTitle {
  color: $neutral-blue-grey;
  font-size: $font-size-medium;
  font-weight: normal;
  margin-bottom: 0;
}

.icons {
  color: $neutral-dark-grey;
  display: inline;
  position: absolute;
  top: 0;
  right: 0;
}

.icon + .icon {
  margin-left: $spacing-xlarge;
}

.footer {
  margin-top: $spacing-large;
}

.checkbox:not(:first-child) {
  margin-left: $spacing-xlarge;
}
