@import "app/styles/variables";
@import "app/styles/colors";

.total {
  text-align: center;
  line-height: 1.1;
}

.amount {
  font-size: $font-size-5xlarge;
  font-weight: bold;
  margin-bottom: 0;
}

.description {
  color: $neutral-blue-grey;
  font-size: $font-size-xxlarge;
  line-height: 1;
  margin-bottom: 0;
  margin-top: $spacing-small;
}

.compared {
  font-size: $font-size-xlarge;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: $spacing-small;
}

.increase,
.decrease {
  font-size: $font-size-medium;
  margin-right: $spacing-small;
}

.increase {
  color: $green-darkest;

  .reverseColors & {
    color: $red-darkest;
  }
}

.decrease {
  color: $red-darkest;

  .reverseColors & {
    color: $green-darkest;
  }
}

.period {
  color: $neutral-blue-grey;
  font-size: $font-size-large;
  line-height: 1.333;
  margin-bottom: 0;
  margin-top: $spacing-small;
}

.info {
  color: $neutral-blue-grey-light;
  font-size: $font-size-xlarge;
  margin-left: $spacing-small;
}
