@import "app/styles/colors";

.timeLineWrapper {
  display: flex;
  margin-bottom: 10px;
  .label {
    width: 115px;
    padding-right: 25px;
  }
  .timeLine {
    flex-grow: 1;
    position: relative;
    .theLine {
      border-top: 1px solid $gray-1;
      position: absolute;
      width: 100%;
      top: 12px;
      z-index: 1;
    }
  }
}

.timeLineItem {
  padding: 0 5px;
  z-index: 99;
  position: relative;
}

.timeLineIcon {
  background-color: $white;
}
