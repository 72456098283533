@import "app/styles/colors";
@import "app/styles/variables";

.tableTitle {
  margin-bottom: 0;

  .errorText {
    color: $red;
    font-size: $font-size-medium;
    margin-left: $spacing-medium;
  }
}

.error {
  border-color: $red-light;
  border-style: dashed;
}

.card {
  :global(.ant-card-body) {
    padding: 0;
  }
}
