@import "app/styles/variables";
@import "app/styles/colors";

.tooltipBody {
  background-color: $white;
  min-width: 290px;
  max-width: 420px;
  position: relative;

  .contentWrapper {
    padding: $spacing-large;
  }
  .tooltipFooter {
    padding: $spacing-large;
    display: flex;
    justify-content: center;
    * + * {
      margin-left: $spacing-small;
    }
    .spacer {
      margin-right: auto;
    }
  }
}
