@import "app/styles/colors";
@import "app/styles/variables";

.divider {
  &::before {
    content: "";
    width: calc(100% - #{$spacing-medium * 2});
    border-top: 1px solid $neutral-dark-grey;
    margin: $spacing-medium;
    margin-bottom: $spacing-medium + $spacing-xsmall;
  }
}
