@import "app/styles/colors";
@import "app/styles/variables";

.breadcrumb {
  font-size: $font-size-xlarge;
  line-height: $font-size-4xlarge;

  :global(.ant-breadcrumb-link) a {
    color: $neutral-blue-grey-light;

    &:hover {
      color: $blue-darkest;
    }
  }
}

.buttonColumn {
  width: 100%;

  @media screen and (min-width: $screen-lg) {
    width: auto;
  }
}
