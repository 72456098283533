@import "app/styles/variables";
@import "app/styles/colors";

.container {
  margin-bottom: $spacing-xlarge;
}

.filter {
  width: 100%;
}

.label {
  font-size: $font-size-small;
  margin-bottom: 0;
}
