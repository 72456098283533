@import "app/styles/variables";

:global(.ant-form-item-label > label) {
  text-transform: uppercase;
  font-size: $font-size-small;
  font-weight: $font-weight-500;
}

.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
