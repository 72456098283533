@import "app/styles/variables";
@import "app/styles/colors";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .iconWrapper > * {
    margin-bottom: $spacing-large;
    font-size: $font-size-xxxlarge;
    color: $brand-primary;
  }

  .title {
    font-size: $font-size-xxlarge;
    margin-bottom: $spacing-xxsmall;
  }

  .subTitle {
    font-size: $font-size-medium;
    margin: $spacing-none auto $spacing-medium auto !important;
  }
}
