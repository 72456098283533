@import "app/styles/colors";
@import "app/styles/variables";

.version {
  color: $neutral-dark-blue !important;
  bottom: 0;
  z-index: 2;
  height: 48px;
  background-color: transparent !important;
  width: $sider-width;
  transition: all 0.2s;
  border: none;
  &.collapsed {
    width: $sider-width-collapsed;
  }
  &:hover {
    color: $brand-primary !important;
  }
}
