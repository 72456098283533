@import "app/styles/colors";
@import "app/styles/variables";

.divider {
  background-color: $neutral-grey;
  display: block;
  height: 1px;
  width: 100%;
  margin-top: $spacing-medium;
}

.split {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $screen-xl) {
    flex-direction: row;
    justify-content: space-between;
  }
  > * {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: $spacing-xxlarge;
      @media screen and (min-width: $screen-xl) {
        margin-top: 0;
      }
    }

    @media screen and (min-width: $screen-xl) {
      flex-basis: calc(50% - #{$spacing-medium});
      min-width: 47%;
    }
  }
}

.select {
  width: 100%;
  margin-bottom: $spacing-medium;
}

.icon {
  color: $neutral-dark-grey;
  margin-left: $spacing-small;
}
