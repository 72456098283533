@import "app/styles/variables";

.viewTermText {
  margin-bottom: $spacing-xxxlarge;
  max-width: 45rem;
}

.table {
  margin-bottom: $spacing-xxlarge;
}
