@import "app/styles/colors";

.row {
  cursor: pointer;
}

.table {
  :global {
    .ant-table-expanded-row {
      &:hover {
        background: #fbfbfb !important;
      }
    }
  }
}

.isDeleted {
  opacity: 0.3;
}
