@import "app/styles/variables";

.content {
  text-align: center;
  padding: $spacing-medium 0;
}

.avatar {
  margin-bottom: $spacing-xsmall;
}

.username {
  font-size: $font-size-medium;
  font-weight: $font-weight-800;
}

.loginId {
  font-size: $font-size-xsmall;
  margin-bottom: $spacing-xsmall;
}

.company {
  font-size: $font-size-small;
}

.buttonLanguage {
  margin-right: $spacing-medium;
}

.termsLink {
  margin-top: $spacing-medium;
  margin-bottom: 0;
}
