@import "app/styles/colors";
@import "app/styles/variables";

.filterAlignment {
  margin-left: 3px;
}

.icon {
  font-size: $font-size-xxlarge;
}

.negative {
  color: $red;
}

.positive {
  color: $green;
}

.noValue {
  color: $neutral-dark-grey;
}
