%default {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.fullscreen {
  @extend %default;
  width: 100%;
  height: 100vh;
}

.centered {
  @extend %default;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
