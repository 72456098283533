@import "app/styles/colors";
@import "app/styles/variables";

.container {
  height: 100vh;
}

.sider {
  height: 115vh;

  :global(.ant-layout-sider-trigger) {
    color: $brand-primary;
  }
}

.logo {
  margin: $spacing-xlarge 0 $spacing-xlarge $spacing-xlarge;
  width: 100%;

  :global(.ant-layout-sider-collapsed) & {
    display: none;
  }
}

.logoSmall {
  display: none;
  width: 100%;

  :global(.ant-layout-sider-collapsed) & {
    display: block;
    margin: $spacing-xlarge 0;
  }
}
