@import "app/styles/colors";
@import "app/styles/variables";

.header {
  background: $white;
  height: auto;
  padding: 0 $spacing-xxlarge;

  .pageTitle {
    font-size: $font-size-xxxlarge;
    letter-spacing: $spacing-xsmall;
    margin-top: $spacing-xxlarge;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

.content {
  padding: $spacing-xxlarge;

  & > *:not(:first-child) {
    margin-top: $spacing-xxlarge;
  }
  & > *:last-child {
    margin-bottom: $spacing-xxlarge;
  }
}
