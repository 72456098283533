@import "app/styles/colors";
@import "app/styles/variables";

.pageTitle {
  font-size: $font-size-xxxlarge;
  letter-spacing: $spacing-xsmall;
  margin-bottom: 0;
  text-transform: uppercase;
}

.tableSection {
  margin-left: 3px;
}

.action {
  color: $neutral-dark-blue;
  font-size: $font-size-xxlarge;

  &:before {
    background-color: transparent;
  }
}
