@import "app/styles/variables";

.modal {
  max-width: 850px;
  width: 80% !important; // Necessary in order to override Ant Design inline styles
  @media screen and (max-width: $screen-xs) {
    width: 95% !important;
  }
  :global(.ant-modal-body) {
    padding: $spacing-xxlarge;
    @media screen and (max-width: $screen-xs) {
      padding: $spacing-large;
    }
  }
}

.error {
  margin-bottom: $spacing-large;
}

.buttons {
  margin-top: $spacing-xlarge;
}

.submitButton {
  margin-left: $spacing-medium;
}
