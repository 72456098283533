@import "app/styles/variables";
@import "app/styles/colors";

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    margin-bottom: $spacing-none;
    text-transform: uppercase;
    font-size: $font-size-xlarge;
  }
  .category {
    margin-bottom: $spacing-none;
  }
  .feedback {
    width: 100%;
    margin-bottom: 0;
  }
}
