@import "app/styles/variables";
@import "app/styles/colors";

.input {
  width: 100%;
}

.checkboxErrorMsg {
  opacity: 0;
  transition: all 0.2s;
  &.isCheckboxError {
    opacity: 1;
  }
}

.incubationTimeSelector {
  top: 1px !important;
}

.removePadding {
  & > div {
    padding: 0;
  }
}

.removeBottomPaddingAndMargin {
  padding-bottom: 0;
  margin-bottom: 0;
}
