// Primary colours (brand / logo)
$brand-primary: #253772;
$brand-secondary: #229963;

/*
  Secondary Colors
  The secondary digital colour palette consists of variations
  of blue, red, green and yellow.

  The blue is used for links and buttons.
  
  The red is used to draw extra attention to an element, e.g.
  error messages etc.

  The green and yellow colours are used for graphs and diagrams,
  where many colours are necessary.
*/
// Blue
$blue-lightest: #cfe6fb;
$blue-ight: #9fcdf7;
$blue: #6eb5f2;
$blue-dark: #4ba2f0;
$blue-darkest: #0e83ea;

// Green
$green-lightest: #d3ebe0;
$green-light: #a7d6c1;
$green: #7ac2a1;
$green-dark: #4ead82;
$green-darkest: #229963;

// Red
$red-lightest: #fbd1d7;
$red-light: #f7a3af;
$red: #f47686;
$red-dark: #f0485e;
$red-darkest: #ec1a36;
$red-1: #e00025;

// Yellow
$yellow-lightest: #fef1d0;
$yellow-light: #fee3a1;
$yellow: #fdd471;
$yellow-dark: #fdc642;
$rellow-darkest: #fcb813;

/*
  Neutral Colours
  Neutral digital colours are the dark blue, white and light grey.

  The white is used for background. The dark blue is used for text
  and other graphic elements.

  The light grey is used for graphic elements, e.g. dividers, strokes
  on buttons, inactive states and background for main side / in page navigation.
*/
$neutral-dark-blue: #001233;
$neutral-blue: #3a3a56;
$neutral-blue-grey: #5e5e74;
$neutral-blue-grey-light: #838393;
$neutral-dark-grey: #a7a7b1;
$neutral-grey: #f0f0ef;

// Black and White
$black: #000000;
$white: #ffffff;

/*
  Custom colors designed by Nodes
*/
$neutral-light-grey: #fafafa;

/* 
  Gray
*/
$gray-1: #acacac;
$gray-2: #e8e8e8;
$gray-3: #595959;
$gray-4: #d6d6d6;
